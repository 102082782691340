<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<!-- Page centrale de la homepage -->

<div id="home_search">
  <img
    src="../../../assets/images/homepage/pictos.svg"
    id="homeImg"
    alt="services pictogram"
  />
  <div class="my-2 text-center">
    <span id="home_title">{{ "HOME_TITLE" | translate }}</span>
    <h1 id="home_subtitle" class="text-center">
      {{
        "HOME_SUBTITLE"
          | translate : { brandName: THEME_CONFIGURATION.brandName }
      }}
    </h1>
  </div>

  <app-search-bar
    (updateLocation)="updateLocation($event)"
    (launchSearch)="launchSearch()"
    [search]="search"
  ></app-search-bar>
  <br />
  <br />
</div>
<div class="container">
  <div class="row">
    <div id="home_search_buttons_cont">
      <img
        id="arrowup"
        src="../../../assets/images/homepage/arrow.png"
        alt="Home arrow"
        aria-hidden="true"
      />
      <div id="home_search_buttons" class="row justify-content-center">
        <div class="col-5 col-md-2 p-0 m-2">
          <button
            type="button"
            class="btn"
            [attr.aria-label]="'Choisir la catégorie ' + ('ALL' | translate)"
            (click)="clearCategories()"
          >
            <img
              src="../../../assets/images/homepage/homepictos.png"
              alt="Home "
              title="home pictos"
              id="all-cat-icon"
            />
            <span>{{ "ALL" | translate }}</span>
          </button>
        </div>
        <ng-container
          *ngFor="
            let categoryHighlighted of CATEGORIES_HIGHLIGHTED;
            let i = index
          "
        >
          <div class="col-5 col-md-2 p-0 m-2">
            <button
              type="button"
              class="btn"
              [attr.aria-label]="
                'Choisir la catégorie ' +
                (categoryHighlighted | uppercase | translate)
              "
              (click)="putTextCat(categoryHighlighted)"
            >
              <span
                [class]="'category-icon category-icon-' + categoryHighlighted"
              ></span>
              <span>{{ categoryHighlighted | uppercase | translate }}</span>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="container" id="allCats">
  <div class="my-4 home_section">
    <div class="text-center">
      <button class="btn btn-primary" (click)="showCats()" type="button">
        {{ "VOIR_CATEGORIES" | translate }}
      </button>
    </div>
    <div
      [@smoothCollapse]="!hideCategories ? 'initial' : 'final'"
      class="step_form home-shadow"
      [ngClass]="{ blue: hideCategories }"
    >
      <div class="row">
        <ng-container *ngFor="let categoryRoot of CATEGORIES_ROOT_NODES">
          <div class="col-12 col-md-3 p-2 text-primary">
            <h5
              (click)="putTextCat(categoryRoot.id)"
              [class]="search.category === categoryRoot.id ? 'active' : ''"
            >
              <span
                [class]="'category-icon category-icon-' + categoryRoot.id"
              ></span>
              {{ categoryRoot.id | uppercase | translate }}
            </h5>
            <ul>
              <li
                *ngFor="let category of categoryRoot.children"
                (click)="putTextCat(category)"
                [class]="search.category === category ? 'active' : ''"
              >
                <span
                  [class]="'category-icon category-icon-' + category"
                ></span>
                {{ category | uppercase | translate }}
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-home-territories-stats />

<div
  class="container my-5"
  *ngIf="!IS_WEBVIEW_APP && THEME_CONFIGURATION.mobileApp"
>
  <div id="app-dl" class="row align-items-center justify-content-md-center">
    <div class="col-md-8 text-center">
      <h3>
        {{
          "SITE_MOBILE"
            | translate : { brandName: THEME_CONFIGURATION.brandName }
        }}
      </h3>

      <div id="app-icons">
        <a
          [attr.aria-label]="'DOWNLOAD_THE_APP_TO_ANDROID' | translate"
          [href]="THEME_CONFIGURATION.mobileApp.androidLink"
          (click)="captureEvent('select-android-app')"
        >
          <img
            class="img-fluid"
            [alt]="'AVAILABLE_ON_GOOGLE_PLAY' | translate"
            src="/assets/images/homepage/google-play.png"
            loading="lazy"
          />
        </a>
        <a
          [attr.aria-label]="'DOWNLOAD_THE_APP_TO_APPLE' | translate"
          [href]="THEME_CONFIGURATION.mobileApp.appleLink"
          (click)="captureEvent('selec-ios-app')"
        >
          <img
            class="img-fluid"
            [alt]="'AVAILABLE_ON_THE_APP_STORE' | translate"
            src="/assets/images/homepage/app-store.png"
            loading="lazy"
          />
        </a>
      </div>
      <br />
      <p>
        {{
          "HOME_MOBILES"
            | translate : { brandName: THEME_CONFIGURATION.brandName }
        }}
      </p>
    </div>
  </div>
</div>
