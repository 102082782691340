<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="page-content-header"></div>
<div class="container">
  <div class="page-content page-content-overlay">
    <h1>Privacy Policy</h1>
    <h2>How useful is this Policy?</h2>

    <p>
      <strong>
        Solinum, which manages the website
        <a href="https://soliguia.es">soliguia.es</a> (“Soliguia”), attaches
        great importance to the protection and confidentiality of your personal
        data, which represent for us a pledge of seriousness and trust.
      </strong>
    </p>
    <p>
      The Privacy Policy specifically reflects our
      <strong
        >willingness to enforce compliance within Solinum with applicable data
        protection rules</strong
      >
      and, in particular, with the General Data Protection Regulation ("GDPR").
    </p>
    <p>
      In particular, the Privacy Policy aims to inform you about how and why we
      process your data in connection with the <b>services</b> we provide.
    </p>

    <h2>Who is this Policy for?</h2>

    <p>
      The Policy applies to <b>you</b>, regardless of where you live, as long as
      you are <b>visitors</b> of our site or
      <b>users of our Soliguia service</b>.
    </p>

    <p>
      <strong
        >Individuals under the age of 15 are informed that they may only use our
        services with the prior consent of their parents, which must be given in
        writing to
        <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a></strong
      >. If you believe that we may be holding information about one of your
      children under the age of 15 without your consent, please contact us.
    </p>

    <h2>Why do we process your data?</h2>

    <p>
      To provide our services, we have to process your personal data for so that
      you can:
    </p>
    <ul>
      <li>
        <b>use and benefit from our service and all its features</b> (e.g.
        search for anti-poverty services, referencing of businesses, etc.) on
        the basis of our Terms of Service.
      </li>
      <li>
        <b>provide administrator and user account management</b> (e.g. account
        creation, service access, and account deletion) based on our Terms of
        Service.
      </li>
      <li>
        <b>communicate with our support department</b> via our internal
        messaging system on the basis of our Terms of Service.
      </li>
      <li>
        <b>receive our technical emails</b> (e.g. modification of passwords,
        etc.) essential to the proper functioning of our service on the basis of
        our Terms of Service.
      </li>
      <li>
        <b>run videos in order to provide you with a quality service</b>, based
        on our Terms of Service.
      </li>
      <li>
        <b>upload and import documents to our platform</b> on the basis of our
        Terms of Service.
      </li>
      <li>
        <b>locate your position</b> when you use our service in order to offer
        you a personalized and adapted service, on the basis of your consent.
      </li>
      <li>
        <b>ensure and enhance the security and quality of our services</b> on a
        day-to-day basis (e.g. statistics, data security, etc.) based on our
        legal obligations, our Terms of Service and our legitimate interest in
        ensuring the proper functioning of our services.
      </li>
    </ul>

    <p>
      Your data is collected <b>directly from you</b> when you use our website
      and we undertake to process your data only for the
      <b>reasons described above</b>.
    </p>

    <p>
      On the other hand, when you voluntarily publish content on the pages we
      publish on social networks, you acknowledge that you are
      <b>entirely responsible</b> for any personal information you may transmit,
      whatever the nature and origin of the information provided.
    </p>

    <p>
      For cookies, please see our dedicated
      <a href="/politique-cookies"><b>Cookie Policy</b></a> available on our
      website.
    </p>

    <h2>What data do we process and for how long?</h2>

    <p>
      We have summarized the <b>categories of personal</b> data that we collect
      and their respective <b>duration of retention</b>.
    </p>
    <p>
      If you wish to obtain further details on the
      <b>retention periods</b> applicable to your data, you can contact us at:
      <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a>.
    </p>

    <ul>
      <li>
        <b>For individuals, personal identification data</b> (e.g. last name,
        first name) <b>and coordinates</b> (e.g. email address) kept for the
        entire duration of the provision of the service to which are added the
        legal limitation periods which are generally 5 years.
      </li>
      <li>
        <b>For professionals, personal identification data</b> (e.g. last name,
        first name, position, company, department, etc.)
        <b>and contact information</b> (e.g. email address and business phone
        number, etc.) are kept for the duration of the provision of the service,
        in addition to the legal statute of limitations, which is generally 5
        years
      </li>
      <li>
        <b>Email address to receive our technical messages</b> kept until your
        account is deleted.
      </li>
      <li>
        <b>Statistical data relating to the viewing of our videos</b> which are
        anonymized and stored indefinitely.
      </li>
      <li><b>Geolocation data</b> stored for 2 months.</li>
      <li>
        <b>Connection data</b> (e.g. logs, IP address, etc.) kept for a period
        of 1 year.
      </li>
      <li>
        <b>Cookies</b> are generally stored for a maximum of 13 months. For more
        details on how we use your cookies, please consult our
        <a href="/politique-cookies"><b>Cookie Policy</b></a
        >, which is available at all times on our website.
      </li>
    </ul>

    <p>
      Once the retention periods described above have expired, the deletion of
      your personal data is <b>irreversible</b> and we will no longer be able to
      provide it to you after this period. At most, we may only retain anonymous
      data for <b>statistical purposes</b>.
    </p>
    <p>
      Please also note that in the event of <b>litigation</b>, we are required
      to retain <b>all</b> of your data for the duration of the processing of
      the case even after the expiration of the retention periods described
      above.
    </p>

    <h2>What rights do you have to control the use of your data?</h2>

    <p>
      The applicable data protection regulations give you
      <b>specific rights</b> that you can exercise, <b>at any time</b> and
      <b>free of charge</b>, to control how we use your data.
    </p>
    <ul>
      <li>
        Right of <b>access</b> and to obtain a <b>copy</b> of your personal data
        as long as this request is not in opopsition with business secrecy,
        confidentiality, or the secrecy of correspondence.
      </li>
      <li>
        Right of <b>rectification</b> of personal data that are incorrect,
        obsolete or incomplete.
      </li>
      <li>
        Right to <b>object</b> to the processing of your personal data
        implemented for commercial prospecting purposes.
      </li>
      <li>
        Right to request <b>erasure</b> ("right to be forgotten") of your
        personal data that are not essential to the proper functioning of our
        services.
      </li>
      <li>
        Right to the <b>restriction</b> of processing your personal data which
        allows you to freeze the use of your personal data in case of dispute
        about the lawfulness of a processing.
      </li>
      <li>
        Right to ask for the <b>portability</b> of your data which allows you to
        download part of your personal data in order to store it or transmit it
        easily from one information system to another.
      </li>
      <li>
        Right to provide <b>guidelines</b> on the fate of your data in the event
        of your death either by you, a trusted third party or an heir.
      </li>
    </ul>

    <p>
      <b>To be considered</b>, your request has to be done by <b>you</b> only at
      the address
      <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a>. Any
      request that not follows this process <b>cannot be treated</b>.
    </p>

    <p>
      Requests cannot be made by anyone other than you. Therefore, we may ask
      you to provide <b>proof of identity</b> if there is any doubt about your
      identity.
    </p>

    <p>
      We will respond to your request <b>as quickly as possible</b>, within
      <b>one</b> month of receipt, unless the request is complex or repeated. In
      this case, the response time may be <b>up to three months</b>.
    </p>

    <p>
      Please note that we may always <b>refuse</b> to respond to any
      <b>excessive or unfounded</b> request, especially if it is
      <b>repetitive</b>.
    </p>

    <h2>Who can access your data?</h2>

    <p class="text-uppercase">
      <strong
        >WE NEVER TRANSFER OR SELL YOUR PERSONAL DATA TO THIRD PARTIES OR
        BUSINESS PARTNERS. ALL OF YOUR PERSONAL DATA ARE USED EXCLUSIVELY BY OUR
        STAFF OR BY OUR IT SERVICE PROVIDERS.</strong
      >
    </p>

    <p>
      More specifically, we only share your data with people who are
      <b>duly authorized</b> to use it to provide you with our service, such as
      our IT department or our customer relations department.
    </p>

    <p>
      Your personal data can also be transferred to our service providers for
      the sole purpose of technically operating our service (e.g. data host).
    </p>

    <p>
      We specify that we
      <b>audit every service providers before recruiting them</b> in order to
      verify that they are stricly complying with the applicable rules regarding
      personal data protection.
    </p>

    <h2>How do we protect your data?</h2>

    <p>
      We implement all the <b>technical</b> and
      <b>organizational means required</b> to guarantee the <b>security</b> of
      your data and, in particular, to fight against any risk of destruction,
      loss, alteration or unauthorized disclosure of your data (e.g. training,
      access control, passwords, etc.).
    </p>

    <h2>Can your data be transferred outside the European Union?</h2>

    <p>
      Unless strictly necessary and on an exceptional basis, we never transfer
      your data outside the European Union and your data is always hosted on
      <b>European soil</b>. In addition, we undertake to make our best to
      recruit only service providers who host your data within the European
      Union.
    </p>

    <p>
      In case we are using service providers that need to transfer your personal
      data outsitde the European Union, we undertake to verify that they are
      implementing
      <b
        >appropriate safeguards to ensure the confidentiality and protection of
        your personal data</b
      >.
    </p>

    <h2>Who can you contact for more information?</h2>

    <p>
      You can contact our DPO at any time and free of charge at
      <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a> in order to
      obtain more information or details on how we process your personal data.
    </p>

    <p>
      We have officially appointed an
      <b>outsource and independant Data Protection Officer ("DPO")</b> to our
      supervisory authority in order to ensure the safety and confidentiality of
      your personal data.
    </p>

    <h2>How can you contact the CNIL?</h2>

    <p>
      You may at any time contact the "<b
        >Commission nationale de l'informatique et des libertés</b
      >" or "<b>CNIL</b>" at the following address: CNIL Complaints Department,
      3 place de Fontenoy - TSA 80751, 75334 Paris Cedex 07 or by phone at
      <a href="tel:+33153732222">+33(0)1.53.73.22.22</a>.
    </p>

    <h2>Can the Policy be modified?</h2>

    <p>
      We may <b>change</b> our Privacy Policy at <b>any time</b> to adapt it to
      new <b>legal requirements</b> as well as to <b>new processing</b> that we
      may implement in the future.
    </p>

    <p>
      <em
        >Certified by
        <a href="https://www.dipeeo.com/"><u>Dipeeo</u></a> &reg;</em
      >
    </p>
  </div>
</div>
