/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import { SortingOrder } from "@soliguide/common";

export class ManageSearchOptions {
  public sortBy: string = "createdAt";
  public sortValue = SortingOrder.DESCENDING;
  public limit = 100;
  public page = 1;

  constructor(data?: { options: Partial<ManageSearchOptions> }) {
    if (typeof data?.options !== "undefined") {
      const options = data.options;
      this.sortBy = (data && options.sortBy) || "createdAt";
      this.sortValue = (data && options.sortValue) || SortingOrder.DESCENDING;
      this.limit = (data && options.limit) || 100;
      this.page = (data && options.page) || 1;
    }
  }
}
