/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";

import { ALL_PUBLICS, LANGUAGES_LIST, PUBLICS_LABELS } from "@soliguide/common";

import { ToastrService } from "ngx-toastr";

import { Search } from "../../interfaces";

import { SearchFilterParams, LanguagesArray } from "../../../../models";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { Observable, merge, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { PosthogService } from "../../../analytics/services/posthog.service";

@Component({
  selector: "app-search-filters",
  templateUrl: "./search-filters.component.html",
  styleUrls: ["./search-filters.component.css"],
})
export class SearchFiltersComponent implements OnInit {
  @Input() public search!: Search;
  @Input() public parcoursSearch!: Search;
  @Input() public searchSubject!: Subject<Search>;
  @Input() public parcoursSearchSubject!: Subject<Search>;
  @Input() public filters!: SearchFilterParams;
  @Input() public showFilters: boolean;

  public readonly ALL_PUBLICS = ALL_PUBLICS;
  public readonly PUBLICS_LABELS = PUBLICS_LABELS;
  public readonly LANGUAGES_LIST = LANGUAGES_LIST;
  public languagesArray: LanguagesArray[];
  public languagesFilterValue: string;

  @Output() public readonly updateFilters = new EventEmitter<void>();

  public constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly translateService: TranslateService,
    private readonly posthogService: PosthogService
  ) {
    this.showFilters = false;
    this.languagesArray = [];
    this.languagesFilterValue = "";
  }

  // Search language part
  @ViewChild("languageSearch", { static: true })
  public languageSearch: NgbTypeahead;

  public ngOnInit(): void {
    // Generate Typeahead data

    for (const shortLang in this.LANGUAGES_LIST) {
      this.languagesArray.push({
        shortLang,
        name: this.translateService.instant(
          `LANGUE_${shortLang.toUpperCase()}`
        ),
        nativeName: this.LANGUAGES_LIST[shortLang].nativeName,
      });
    }

    this.languagesArray.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });

    this.translateService.onLangChange.subscribe({
      next: () => {
        this.languagesArray = [];

        for (const shortLang in this.LANGUAGES_LIST) {
          this.languagesArray.push({
            shortLang,
            name: this.translateService.instant(
              `LANGUE_${shortLang.toUpperCase()}`
            ),
            nativeName: this.LANGUAGES_LIST[shortLang].nativeName,
          });
        }

        this.languagesArray.sort((a, b) => {
          return a.name < b.name ? -1 : 1;
        });
      },
    });
  }

  // Search language functions
  public inputFormatter = (currentLanguageFilter: LanguagesArray): string => {
    return currentLanguageFilter.name;
  };

  public searchLanguage = (
    text$: Observable<string>
  ): Observable<LanguagesArray[]> => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );

    return merge(debouncedText$).pipe(
      map((term: string) => {
        this.languagesFilterValue = term;

        if (this.filters.languages) {
          this.filterString("languages", "", "");
        }

        return (
          term === ""
            ? this.languagesArray
            : this.languagesArray.filter(
                (v) =>
                  v.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                  v.nativeName.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
        ).slice(0, 10);
      })
    );
  };

  public onInputBlur = () => {
    if (
      !this.filters.languages &&
      document.activeElement !== document.getElementById("language-input")
    ) {
      this.languagesFilterValue = "";
    }
  };

  public filterString = (type: string, searchPrefix = "", value = ""): void => {
    let _value = value;

    if (type === "age") {
      const input = document.getElementsByName("filterAge")[0];

      if (input.matches(":invalid")) {
        this.toastr.warning(this.translateService.instant("AGE_BAD_VALUE"));
        _value = "";
      }
    }

    if (_value) {
      this.activeFilter(type, searchPrefix, _value);
    } else {
      this.removeFilter(type, searchPrefix);
    }

    this.launchSearch();
  };

  public filterBoolean = (type: string, searchPrefix = ""): void => {
    if (this.filters[type]) {
      this.removeFilter(type, searchPrefix);
    } else {
      this.activeFilter(type, searchPrefix, true);
    }

    this.launchSearch();
  };

  private readonly activeFilter = (
    type: string,
    searchPrefix: string,
    value: string | boolean
  ): void => {
    this.captureEvent(`click-add-filter-${type}`, { newValue: value });
    this.filters[type] = value;

    if (searchPrefix === "") {
      this.search[type] = value;
      this.parcoursSearch[type] = value;
    } else {
      if (typeof this.search[searchPrefix] === "undefined")
        this.search[searchPrefix] = {};

      if (typeof this.parcoursSearch[searchPrefix] === "undefined")
        this.parcoursSearch[searchPrefix] = {};

      if (searchPrefix === "publics") {
        if (
          this.search.publics[type] &&
          typeof this.search.publics[type] === "object"
        ) {
          if (this.search.publics[type].includes(value)) {
            const index = this.search.publics[type].indexOf(value);
            this.search.publics[type].splice(index, 1);
          } else {
            this.search.publics[type].push(value);
          }
        } else {
          this.search.publics[type] = [value];
        }
      } else {
        this.search[searchPrefix][type] = value;
        this.parcoursSearch[searchPrefix][type] = value;
      }
    }
  };

  private readonly removeFilter = (
    type: string,
    searchPrefix: string
  ): void => {
    this.captureEvent(`click-remove-filter-${type}`);
    delete this.filters[type];

    if (searchPrefix === "") {
      delete this.search[type];
      delete this.parcoursSearch[type];
    } else {
      if (typeof this.search[searchPrefix] !== "undefined")
        delete this.search[searchPrefix][type];

      if (typeof this.parcoursSearch[searchPrefix] !== "undefined")
        delete this.parcoursSearch[searchPrefix][type];
    }
  };

  private readonly launchSearch = (): void => {
    this.updateFilters.emit();

    this.router
      .navigate(["."], {
        relativeTo: this.route,
        queryParams: { ...this.filters, placePage: null, parcoursPage: null },
      })
      .then(() => {
        this.searchSubject.next(this.search);
        this.parcoursSearchSubject.next(this.parcoursSearch);
      });
  };

  public captureEvent(eventName: string, properties?: Record<string, any>) {
    this.posthogService.capture(`search-filters-${eventName}`, {
      ...properties,
      search: this.search,
      parcoursSearch: this.parcoursSearch,
      filters: this.filters,
      showFilters: this.showFilters,
    });
  }
}
