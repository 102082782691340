<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="page-content-header"></div>
<div class="container">
  <div class="page-content page-content-overlay">
    <h1>Terms and conditions of use of Soliguia</h1>
    <h2>1. Who we are</h2>
    <p>
      The Soliguia platform and widget ("Soliguia") are published by Solinum, an
      association under the law of 1901, whose registered office is at 16 Place
      des Quinconces 33000 Bordeaux, France.
    </p>
    <p>
      You can contact us at any time at
      <a href="mailto:contact@solinum.org">contact@solinum.org</a>.
    </p>
    <p>
      Soliguia is hosted by Clever Cloud located at 3 rue de l'Allier, 44000
      Nantes, OVH located at 2 rue Kellermann, 59100 Roubaix and Scaleway
      located at 8 rue de la Ville l'Evêque, 75008 Paris, whose servers are
      located within the European Union.
    </p>

    <h2>2. What is the purpose of general terms and conditions?</h2>
    <p>
      The trust you place in us every day is our priority. That's why we do our
      utmost to ensure that you can maintain this trust on a day-to-day basis,
      which of course means respecting our General Terms and Conditions of Use
      (<b>"T&C"</b>).
    </p>
    <p>
      The T&C, which apply to all services provided by Soliguia, are intended to
      set out the rules that you must follow when using the site, as any use of
      Soliguia implies unreserved acceptance of the T&C.
    </p>
    <p>
      You therefore certify that you have read the T&C prior to any use of
      Soliguia and that you have all the information and skills necessary to
      ensure that the services provided correspond to your needs.
    </p>
    <h2>3. What are the conditions for opening an account?</h2>
    <p>
      To create an account on Soliguia, you must be a natural person, of legal
      age, with full legal capacity to enter into commitments under the T&C.
    </p>
    <p>
      Your account is strictly personal. You therefore undertake to provide the
      identification data required to create the account in a personal, correct,
      accurate and truthful manner. You must also inform us of any changes to
      the information required for normal use of Soliguia.
    </p>
    <p>
      You are responsible for maintaining the confidentiality of your account
      and password, and for restricting access to your terminal (e.g. tablet,
      mobile, etc.). If you have any doubts about the vulnerability of your
      account or the confidentiality of your access, you must change your
      password and/or contact our support department as soon as possible.
      Otherwise, and to the extent permitted by applicable law, you are
      responsible for all activities that occur under your account.
    </p>
    <p>
      We recommend using a complex password (made up of numbers, upper and lower
      case letters and punctuation marks) to increase account access security,
      and changing your password regularly when accessing your account settings.
    </p>
    <h2>4. Is the Soliguia widget free?</h2>
    <p>
      The Soliguia widget is currently provided free of charge. However, we
      reserve the right to charge for the use of the widget or certain of its
      functions. In this case, you will of course be notified before any changes
      are made to the rules governing the use of the Soliguia widget.
    </p>
    <h2>5. What are our commitments?</h2>
    <p>
      We do our utmost to provide you with a quality service, accessible 24/7,
      offering accurate and precise content, free from any risk of technical
      failure.
    </p>
    <p>
      However, as there is no such thing as "zero risk" when it comes to
      information technology, we cannot be held liable for any damage that may
      occur as a result of using Soliguia, as we cannot be held responsible for
      any errors, unavailability or interruptions that may occur on Soliguia, or
      in the event of a virus being accessible from Soliguia. We are also not
      responsible for any delay or inability on your part to meet your
      obligations under the T&C if the delay or inability results from any cause
      beyond our reasonable control.
    </p>
    <p>
      Nor are we responsible for any hacking, errors or viruses that may be
      installed on your terminal and affect the service, or for any steps or
      actions taken or omitted by a system administrator.
    </p>
    <p>
      In addition, your access to Soliguia or the services may occasionally be
      suspended or limited to allow for repairs, maintenance, or to add a new
      feature or service.
    </p>
    <p>
      In all cases, it is expressly agreed that we are subject only to an
      obligation of means for the provision of Soliguia. You further agree that
      we shall only be liable in the event of fault on our part, it being
      understood that this liability shall only apply to direct and foreseeable
      damage, indirect damage being excluded, and that we shall not be liable
      for commercial losses (including loss of profit, contracts, anticipated
      savings, data or superfluous expenditure), which were not foreseeable when
      you began to use Soliguia.
    </p>
    <p>
      Indirect damage includes loss of data, time, sales, margins, orders,
      members, operations, income, brand image, expected results, third-party
      action and, in general, any disturbance of any kind related to your
      business. Similarly, any action by a third party against you is considered
      indirect.
    </p>
    <p>
      Furthermore, you are informed that any case of force majeure immediately
      and automatically suspends the performance of Soliguia. In the event of
      force majeure lasting more than two months, we reserve the right to
      suspend or terminate the service without notice or compensation.
    </p>
    <p>
      In particular, events beyond our control which could not reasonably have
      been foreseen when Soliguia was made available and whose effects cannot be
      avoided by appropriate measures and which prevent its use are considered
      to be force majeure.
    </p>
    <p>
      In particular, these are those usually retained by the jurisprudence of
      French courts and tribunals, such as war, riot, fire, internal or external
      strikes, lock-out, occupation of premises, bad weather, earthquake, flood,
      water damage, legal or governmental restrictions, legal or regulatory
      modifications, accidents of all kinds, epidemics, pandemics, illness
      affecting more than 10% of our association's staff within a period of two
      consecutive months, lack of energy supply, partial or total shutdown of
      the Internet network and, more generally, of private or public
      telecommunications networks, road blockages and supply impossibilities and
      any other event beyond our control preventing the normal performance of
      Soliguia.
    </p>
    <h2>6. What are your obligations and duties?</h2>
    <p>
      Soliguia and its services are intended for informational use only and may
      not be used for any commercial purpose. You agree not to sell, rent or
      commercialize any Soliguia elements.
    </p>
    <p>
      You further agree not to arrange, modify, decompile, disassemble, reverse
      engineer, translate, adapt, reproduce, distribute, disseminate or transfer
      any information, software, products or, in general, any element of
      Soliguia. Re-posting, scrapping, or automated or high-volume use of
      Soliguia is expressly prohibited.
    </p>
    <p>
      You agree to comply at all times when using Soliguia (i) with applicable
      national and international laws and regulations and (ii) with the T&C.
    </p>
    <p>
      <strong
        >YOU WARRANT THAT YOU WILL NOT USE Soliguia FOR ANY PURPOSE THAT IS
        UNLAWFUL, IMMORAL OR CONTRARY TO ITS INTENDED USE AND/OR
        PURPOSE.</strong
      >
    </p>
    <p>
      By using Soliguia, you acknowledge that you only have a right of use for
      your account. In all cases, you agree not to, directly or indirectly :
    </p>
    <ul>
      <li>
        use Soliguia in such a way as to infringe the intellectual and
        industrial property rights, privacy rights and/or image rights and/or
        any other rights of others.
      </li>
      <li>
        create, use, share and/or publish by any means (forum, public profile or
        other) any material (text, words, images, sounds, videos, etc.) or
        content that is, in our sole judgment, aggressive, threatening,
        malicious, defamatory, untrue, pornographic, pedophilic, obscene,
        vulgar, racist, xenophobic, hateful, sexually explicit, violent,
        contrary to morality or in any other way objectionable.
      </li>
      <li>
        create, use, share and/or publish by any means (forum, public profile or
        other) any material (text, words, images, sounds, videos, etc.) which
        would constitute a breach of confidentiality or which would incite to
        commit an illicit activity (in particular, piracy, cracking or
        distribution of counterfeit software).
      </li>
      <li>
        carry out activities aimed at accessing elements or functionalities of
        the services whose use is made without our prior and express
        authorization.
      </li>
      <li>
        arrange, modify, translate, adapt, reproduce, index, copy and/or extract
        any information, software, product, database or any other element or
        part of Soliguia, by any means without our prior and express
        authorization.
      </li>
      <li>
        modify, distort, block, abnormally burden, disrupt, slow down, and/or
        hinder the normal operation of all or part of Soliguia, or their
        accessibility to other users, or the operation of partner networks, or
        attempt to do any of the foregoing.
      </li>
      <li>
        transmit or propagate any virus, Trojan horse, worm, bomb, corrupted
        file and/or similar destructive device or corrupted data, and/or
        organize, participate or be involved in any way in an attack against our
        servers and/or Soliguia and/or the servers and/or services of our
        service providers and partners.
      </li>
      <li>
        harass other Soliguia users, send them unsolicited messages for
        commercial prospecting purposes, use the services for surveys, contests,
        pyramid sales or similar operations, or to send emailing, spam or any
        unsolicited advertising or promotional material, for commercial or
        non-commercial purposes.
      </li>
      <li>
        use the account of another Soliguia user, impersonate another person, or
        otherwise misrepresent yourself in dealings with any person or entity.
      </li>
      <li>
        collect or intercept by any means not expressly permitted data exchanged
        by other users, or the names/pseudonyms and/or passwords of any other
        users.
      </li>
      <li>
        attempt to obtain any password, account information or other proprietary
        information from any other Soliguia user, and/or sell, rent, share, loan
        and/or otherwise transfer to any third party your account and/or the
        means to access it and/or otherwise make any third party benefit from
        your account.
      </li>
      <li>make inappropriate use of the assistance service we provide.</li>
      <li>
        access, use, download via Soliguia or otherwise reproduce or provide to
        anyone (whether free of charge or for a fee) any directory of Soliguia
        users or any other information relating to users or the use of Soliguia.
      </li>
    </ul>
    <p>
      If you become aware of any behavior that is malicious or contrary to our
      T&C or the interests of other users, you may contact us at
      <a href="mailto:contact@solinum.org">contact@solinum.org</a>.
    </p>
    <h2>7. Can your account be suspended or deactivated?</h2>
    <p>
      We may limit, restrict, suspend or permanently discontinue all or part of
      your account at any time, without notice or prior notice, in the event of
      a breach of your duties and obligations under the T&C or at the express
      request of an administrative or judicial authority, or in the event of an
      actual or alleged infringement of any right, all without this measure
      being considered a breach of our obligations.
    </p>
    <p>
      Any closure of your administrator account will result in the permanent
      deletion of the personal data provided to us when you opened or updated
      your account. On the other hand, all data relating to your structure that
      is not personal data is retained for the purposes of building up our
      database.
    </p>
    <h2>8. What rules apply to intellectual property?</h2>
    <p>
      We are and remain the exclusive owners of all intellectual property rights
      to the trademarks (e.g. Soliguia, Solinum, etc.), graphics or logos, or
      any other elements that you may view when using Soliguia.
    </p>
    <p>
      We are and will remain the exclusive owners of all intellectual property
      rights and third party licenses applicable to the general structure of
      Soliguia such as, the software used to run it, the algorithms we have
      developed, the APIs we use, the Soliguia content we write and/or select
      (e.g. text, sound, design, Privacy Policy, Cookies Policy, etc.), our
      know-how, etc.
    </p>
    <p>
      The fact that you can access them freely during your use does not imply
      that we are granting you any license or right to use the elements of
      Soliguia.
    </p>
    <p>
      You therefore have no right to use these elements in any way whatsoever,
      in whole or in part, without our prior written authorization. Any
      unauthorized use by you may constitute an infringement punishable under
      articles L335-2 et seq. of the French Intellectual Property Code and
      articles L713-2 et seq. of the same code.
    </p>
    <p>
      Soliguia may also mention, for various reasons, other logos and trademarks
      belonging to us or to third parties and protected by industrial property
      law. Any unauthorized use of these elements constitutes an infringement of
      copyright.
    </p>
    <p>
      In accordance with the provisions of Law No. 98-536 of July 1, 1998,
      transposing into the French Intellectual Property Code Directive 96/9 EC
      of March 11, 1996, on the legal protection of databases, we hereby inform
      you that we are the producer and owner of all or part of the databases
      used via Soliguia. Consequently, any extraction and/or reuse of databases
      within the meaning of articles L342-1 and L342-2 of the French
      Intellectual Property Code is prohibited.
    </p>
    <h2>9. How do we use your personal data?</h2>
    <p>
      When we work together and you use Soliguia, we have no choice but to
      process your personal data, in particular to handle your requests, manage
      our partnership relations, etc.
    </p>
    <p>
      To make it easier for you to understand how we process your data, we have
      drawn up a Privacy Policy, which can be accessed at any time on Soliguia,
      to explain why and how we process your data in connection with the
      services we provide.
    </p>
    <p>
      In some cases, if you include personal data (e.g. cell phone number or a
      person's first and last name), we become a data processor within the
      meaning of the RGPD. In this case, your data is protected by the data
      protection agreement accessible here.
    </p>
    <h2>10. Which "standard" language applies?</h2>
    <p>
      Soliguia can be translated into many different languages. Nevertheless,
      French remains the "standard" language of our association and our
      business. The French language therefore always prevails over other
      versions of Soliguia in our working relationship, particularly in the
      event of misinterpretation, dispute, litigation or difficulty in
      interpreting our services.
    </p>
    <h2>11. What are the rules governing hypertext links?</h2>
    <p>
      Soliguia may contain hyperlinks to third-party websites. You are informed
      that we are neither responsible nor liable for any offers, actions,
      content, products, services or other items available via these links.
    </p>
    <p>
      In addition, we would be grateful if you would inform us in advance of any
      hypertext link present on Soliguia that would allow access to a
      third-party site offering content contrary to the law or morality.
    </p>
    <h2>12. Evidence agreement</h2>
    <p>
      We archive communications, files, contracts, order forms and invoices on a
      reliable and durable medium so as to constitute a faithful and durable
      copy. These communications, files, contracts, order forms and invoices may
      be produced as proof. In the absence of proof to the contrary, the data
      recorded by our teams constitutes proof of all exchanges between you and
      us.
    </p>
    <h2>13. Nullity</h2>
    <p>
      The nullity of one of the clauses hereof does not entail the nullity of
      the T&C and the other clauses will continue to produce their effects.
    </p>
    <h2>14. Waiver</h2>
    <p>
      Solinum's temporary or permanent refusal to invoke one or more clauses of
      the T&C does not in any way imply a waiver of its right to do so.
    </p>
    <h2>15. Which law applies?</h2>
    <p>
      Solinum is an association under French law. Consequently, the use of
      Soliguia is subject to French law and any dispute will be brought
      exclusively before the competent French courts.
    </p>
    <h2>16. Can the general terms and conditions be modified?</h2>
    <p>
      We reserve the right to modify the T&C at any time and without prior
      notice. We therefore advise you to consult them as regularly as possible.
    </p>
  </div>
</div>
