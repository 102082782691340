<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="page-content-header"></div>
<div class="container">
  <div class="page-content page-content-overlay">
    <h1>Cookie Policy</h1>

    <h2>What is the purpose of this Policy?</h2>
    <p>
      <strong>
        Solinum, which manages the site
        <a href="https://soliguia.cat">soliguia.cat</a>
        (&ldquo;Soliguia&rdquo;), attaches great importance to the
        confidentiality of your data which represents for us a pledge of
        seriousness and confidence, no matter whether it is about cookies or
        personal data.
      </strong>
    </p>

    <p>
      The <b>Cookie</b> Policy aims to explain to you why we use cookies as well
      as any other type of <b>trackers</b> when you browse our Soliguia
      platform.
    </p>
    <p>
      On the other hand, this Cookie Policy only concerns the processing related
      to the <b>deposit of cookies and tracers</b>. For the other treatments
      that we carry out, please consult our <b>Privacy Policy</b>,
      <b>accessible at any time</b> on the platform Soliguia.
    </p>

    <h2>What is a cookie and what is its purpose?</h2>
    <p>
      Cookies and other trackers are <b>small &ldquo;text&rdquo;</b>files placed
      and hosted by a server on your smartphone, tablet or computer when you
      visit a website.
    </p>
    <p>
      Cookies <b>do not identify you personally</b> but identify your terminal
      (e.g. smartphone, tablet, etc.) via your IP address, as well as various
      information related to your browsing (e.g. browsing time, pages viewed,
      etc.) in order to:
    </p>
    <ul>
      <li>
        memorize your identifier in order to recognize you when you connect
        again to a website and to keep your choices (e.g. languages, refusal of
        cookies, keeping your basket, etc.).
      </li>
      <li>
        track and analyze your use of a website for statistical purposes in
        order to improve your experience and provide you with a service tailored
        to your needs.
      </li>
      <li>enable you to browse and use a website in optimal conditions.</li>
      <li>
        analyze your use of a website in order to provide you with advertising
        tailored to your needs either on that website or on other websites.
      </li>
    </ul>
    <p>
      For more information on this topic, you can visit the following websites:
      <a href="www.cnil.fr">www.cnil.fr</a>,
      <a href="www.allaboutcookies.org">www.allaboutcookies.org</a> and
      <a href="www.youronlinechoices.eu">www.youronlinechoices.eu</a>.
    </p>

    <h2>Why do we use cookies and for how long?</h2>
    <p>
      When you log on to our Soliguia platform, we may place cookies on your
      terminal <b>only</b> for the <b>following reasons and purposes</b>:
    </p>
    <li>
      <b>Technical cookies</b> that allow us to use our Soliguia service
      securely based on our legitimate interest in providing you with a quality
      service.
    </li>
    <li>
      <b>Technical cookies</b> that enable videos to function on our platform on
      the basis of your prior consent.
    </li>
    <li>
      <b>Statistical cookies</b> that allow us to know how you use our site
      (e.g., length of time spent viewing a page, pages consulted, etc.) on the
      basis of your prior consent, in order to provide you with an adapted and
      quality service.
    </li>
    <p>
      In all cases, the cookies deposited, whatever their nature, are kept for
      <b>a maximum period of 13 months</b> from their installation and are only
      renewed in the event of a new connection to our website at the end of this
      period. If you would like more details on the duration of use of our
      cookies, please contact us at
      <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a>.
    </p>

    <h2>What control do you have on these &ldquo;cookies&rdquo;?</h2>
    <p>
      You cannot <b>oppose the deposit of technical cookies</b> since they are
      necessary for the proper functioning of our site which would otherwise be
      potentially unusable.
    </p>
    <p>
      As mentioned above, this exception does not apply to technical cookies
      placed on your terminal to operate <b>videos</b>. In this case, the
      deposit of cookies is only possible if you have
      <b>previously consented</b>.
    </p>
    <p>
      However, in addition to the rights detailed in our Privacy Policy, you
      obviously have the right to <b>refuse or withdraw your consent</b> to all
      other types of cookies being installed on your terminal.
    </p>
    <p>
      The right to refuse cookies can be exercised at any time using our cookie
      banner. If the cookie banner is no longer displayed, you can also set
      cookies directly on your browser by following the instructions below:
      <b
        ><a
          href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en%20%22Chrome%22"
          >Chrome</a
        ></b
      >,
      <b
        ><a
          href="https://learn.microsoft.com/fr-fr/microsoft-edge/devtools-guide-chromium/storage/cookies"
          >Microsoft Edge</a
        ></b
      >,
      <b
        ><a
          href="https://support.apple.com/fr-fr/guide/safari/sfri11471/16.0/mac/11.0"
          >Safari</a
        ></b
      >,
      <b
        ><a
          href="https://support.mozilla.org/fr/kb/effacer-cookies-donnees-site-firefox"
          >Firefox</a
        ></b
      >
      and
      <b
        ><a
          href="https://blogs.opera.com/tips-and-tricks/2023/04/clean-browser-and-remove-trackers/"
          >Opera</a
        ></b
      >.
    </p>

    <h2>Who can you contact for more information?</h2>
    <p>
      You can contact our DPO at any time and free of charge at
      <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a> in order to
      obtain more information or details on how we process your personal data.
    </p>
    <p>
      We have officially appointed an
      <b
        >outsource and independant Data Protection Officer
        (&ldquo;DPO&rdquo;)</b
      >
      to our supervisory authority in order to ensure the safety and
      confidentiality of your personal data.
    </p>
    <h2>How can you contact the CNIL?</h2>
    <p>
      You may at any time contact the
      <b
        >&ldquo;Commission nationale de l'informatique et des
        libert&eacute;s&rdquo;</b
      >
      or &ldquo;<b>CNIL</b>&rdquo; at the following address: CNIL Complaints
      Department, 3 place de Fontenoy - TSA 80751, 75334 Paris Cedex 07 or by
      phone at <a href="tel:+33153732222">+33(0)1.53.73.22.22</a>.
    </p>

    <h2>Can the Policy be modified?</h2>
    <p>
      We may <b>change</b> our Cookie Policy at <b>any time</b> to adapt it to
      new <b>legal requirements</b> and to <b>new processing</b> operations that
      we may implement in the future. You will of course be informed of any
      changes to this Policy.
    </p>

    <p>
      <em
        >Certified by
        <a href="https://www.dipeeo.com/"><u>Dipeeo</u></a> &reg;</em
      >
    </p>
  </div>
</div>
