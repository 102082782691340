/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import { Component, OnInit, Input } from "@angular/core";

import { PlaceService } from "../../services/place.service";

import { Place } from "../../../../models/place/classes";
import {
  IDF_RER_LIST,
  IDF_TRANSILIEN_LIST,
  LIGNES_TRANSPORTS_BANNIS,
  NAVITIA_REGIONS,
  RESEAUX_BANNIS,
} from "../../../../models/transports/constants";
import {
  TransportLine,
  TransportStop,
} from "../../../../models/transports/types";

import { PlaceType, slugLocation, removeAccents } from "@soliguide/common";
import { PosthogComponent } from "../../../analytics/components/posthog-component.class";
import { PosthogService } from "../../../analytics/services/posthog.service";

@Component({
  selector: "app-display-transports",
  templateUrl: "./display-transports.component.html",
  styleUrls: [
    "./display-transports.component.css",
    "./transports-idf.icons.css",
  ],
})
export class DisplayTransportsComponent
  extends PosthogComponent
  implements OnInit
{
  @Input() public place!: Place;

  public NAVITIA_REGIONS = NAVITIA_REGIONS;

  public navitiaRegionCode = "fr-idf";

  // Tableau des lignes de transport formatés
  public transports: {
    [key: string]: TransportLine[];
  };

  // Tableau des stations triées par distance
  public stopsByDistance: TransportStop[];

  public existingStopPoints: string[];
  public existingLines: string[];

  public itineraryLink = "https://www.google.fr/maps/dir/";

  constructor(
    private readonly placeService: PlaceService,
    posthogService: PosthogService
  ) {
    super(posthogService, "display-public-transports-suggestions");
    this.existingStopPoints = [];
    this.existingLines = [];
    this.transports = {};
    this.stopsByDistance = [];
  }

  public ngOnInit(): void {
    this.itineraryLink +=
      this.place.placeType === PlaceType.PLACE
        ? this.place.position?.address
        : this.place.parcours[0]?.position.address;

    this.updateDefaultPosthogProperties({ itineraryLink: this.itineraryLink });

    const regionToSearch = slugLocation(this.place.position.region);
    const departmentToSearch = slugLocation(this.place.position.department);

    if (typeof NAVITIA_REGIONS[regionToSearch] !== "undefined") {
      this.navitiaRegionCode = NAVITIA_REGIONS[regionToSearch];
    } else if (typeof NAVITIA_REGIONS[departmentToSearch] !== "undefined") {
      this.navitiaRegionCode = NAVITIA_REGIONS[departmentToSearch];
    }

    const url = `https://api.navitia.io/v1/coverage/${this.navitiaRegionCode}/coord/${this.place.position.location.coordinates[0]};${this.place.position.location.coordinates[1]}/stop_schedules?distance=600&count=120`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.placeService.getTransports(url).subscribe((transports: any) => {
      this.generateTransports(transports);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public generateTransports(transports: any) {
    this.stopsByDistance = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transports.stop_schedules.forEach((transport: any) => {
      const transportExist =
        transport.display_informations.label + transport.stop_point.name;

      const network = transport.display_informations.network;

      if (
        this.existingStopPoints.indexOf(transportExist) === -1 &&
        LIGNES_TRANSPORTS_BANNIS.indexOf(
          transport.display_informations.label
        ) === -1 &&
        RESEAUX_BANNIS.indexOf(network) === -1
      ) {
        if (typeof this.transports[transport.stop_point.name] === "undefined") {
          this.transports[transport.stop_point.name] = [];

          const dist = this.calculateDistance(
            this.place.position.location.coordinates[1],
            this.place.position.location.coordinates[0],
            transport.stop_point.coord.lat,
            transport.stop_point.coord.lon
          );

          this.stopsByDistance.push({
            gare: transport.stop_point.name,
            distance: dist,
          });

          this.stopsByDistance = this.stopsByDistance.sort(
            (a: TransportStop, b: TransportStop) => {
              return a.distance - b.distance;
            }
          );
        }

        const transportToAdd = {
          name: transport.stop_point.name,
          label: transport.display_informations.label,
          network: transport.display_informations.network,
          commercial_mode_plain: transport.display_informations.commercial_mode,
          commercial_mode: removeAccents(
            transport.display_informations.commercial_mode
          ),
          class: "default_transport_design",
          style: {
            "background-color": "#" + transport.display_informations.color,
            color: "#" + transport.display_informations.text_color,
          },
        };

        // TODO: Créer un fichier qui permet de gérer les transports sur toutes les régions
        // Traitement particulier des lignes d'île-de-france
        if (this.navitiaRegionCode === "fr-idf") {
          const indexLigne = transport.display_informations.label
            .substr(0, 2)
            .trim();

          // Métro
          if (transportToAdd.commercial_mode.toLowerCase() === "metro") {
            transportToAdd.name = "Métro " + indexLigne;
            transportToAdd.label = indexLigne;
            transportToAdd.commercial_mode = "Métro";
            transportToAdd.class = "metro ligne" + transportToAdd.label;
          }

          // Tramways
          if (transportToAdd.commercial_mode.toLowerCase() === "tramway") {
            transportToAdd.name = "Tramway " + indexLigne;
            transportToAdd.label = indexLigne;
            transportToAdd.commercial_mode = "Tramway";
            transportToAdd.class = "tramway ligne" + transportToAdd.label;
            transportToAdd.style["background-color"] = "#FFFFFF";
          }

          // Affichage particulier des RER
          if (IDF_RER_LIST.indexOf(indexLigne) !== -1) {
            transportToAdd.name = "RER " + indexLigne;
            transportToAdd.label = indexLigne;
            transportToAdd.commercial_mode = "RER";
            transportToAdd.class = "rer ligne" + transportToAdd.label;
          }

          // Affichage des transilien
          if (IDF_TRANSILIEN_LIST.indexOf(indexLigne) !== -1) {
            transportToAdd.name = "Ligne " + indexLigne;
            transportToAdd.label = indexLigne;
            transportToAdd.commercial_mode = "transilien";
            transportToAdd.class = "transilien ligne" + transportToAdd.label;
          }
        }

        // On ajoute la ligne à l'arrêt
        this.transports[transport.stop_point.name].push(transportToAdd);
      }

      // Souvent, plusieurs arrêts d'une même ligne sont proches
      // On ajoute le transport et la ligne à un tableau pour éviter de mettre plusieurs fois les mêmes infos
      this.existingStopPoints.push(transportExist);
      this.existingLines.push(transport.display_informations.label);
    });
  }

  public calculateDistance(
    latFrom: number,
    lngFrom: number,
    latTo: number,
    lngTo: number
  ): number {
    const radlat1 = (Math.PI * latFrom) / 180;
    const radlat2 = (Math.PI * latTo) / 180;
    const theta = lngFrom - lngTo;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;

    return dist;
  }
}
