/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import cloneDeep from "lodash.clonedeep";
import {
  Categories,
  FlatCategoriesTreeNode,
  CATEGORIES,
} from "@soliguide/common";

const CATEGORIES_NODES_WITH_CHILDREN: FlatCategoriesTreeNode[] = cloneDeep(
  CATEGORIES
).filter((currentNode) => currentNode.children.length);

const CATEGORIES_WITH_CHILDREN: Categories[] = cloneDeep(
  CATEGORIES_NODES_WITH_CHILDREN
).map((node) => node.id);

export const CATEGORIES_NODES_WITH_ONE_DEPTH_CHILDREN: FlatCategoriesTreeNode[] =
  cloneDeep(CATEGORIES_NODES_WITH_CHILDREN).map((currentNode) => {
    currentNode.children = currentNode.children.filter(
      (node) => !CATEGORIES_WITH_CHILDREN.includes(node)
    );

    return currentNode;
  });
