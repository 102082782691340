/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
export const RESEAUX_BANNIS: string[] = ["Noctilien", "OUIBUS", "bus"];
export const LIGNES_TRANSPORTS_BANNIS: string[] = [
  "CGY - PES",
  "PAZ - DDN",
  "MEA-PSE",
  "PVS-PES",
  "PES-PRO",
  "PET-CLM",
  "PVS-PES",
  "TOU - PST",
  "VRC-PAZ",
  "Noctilien",
  "TBNight",
  "TRAVERSE",
  "GDN-GVE",
  "NEY-FLA",
  "PON - PET",
  "SDF-PSB",
  "GDN-CRE",
  "PLG - PMT",
  "PMO - PLG",
  "PMO - RBT",
  "PMO - VLC",
  "PMO-DRX",
  "LIGNE-B",
  "RAM MON",
  "DIRECT 1",
  "DIRECT 4",
  "MAU-GDL",
  "MGI-GDL",
  "MEL - PLY",
  "PLY-MEL",
  "LSM- PLY",
  "CHARONN",
  "TUVIM",
  "PET - CBP",
  "ANY-SDF",
  "ASB-PES",
  "CDG-PES",
  "CDG-PSF",
  "MCE-PES",
  "MYC-PSF",
  "PSF-CDG",
  "PSF-MYC",
  "SDF-ANY",
  "LBT-PES",
  "LBT-PSF",
  "PSF-LBT",
];
