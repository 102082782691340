/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import {
  RelationsSearch,
  UserTypes,
  RELATIONS_SEARCH,
} from "@soliguide/common";
import { OrgaCampaignStatus } from "../../../models/campaign/types/CampaignStatus.type";
import { ManageSearch } from "../../../models/manage-search/classes/ManageSearch.class";
import { User } from "../../users/classes";

export class SearchOrgaObject extends ManageSearch {
  public name: string | null;
  public organization_id: number | null;
  public admin?: string;
  public toCampaignUpdate?: boolean;
  public campaignStatus?: OrgaCampaignStatus;
  public lieu_id?: number;

  public relations?: RelationsSearch[];
  public userTypes?: UserTypes[];
  public priority?: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any, user: User) {
    super(data, user);
    this.organization_id = data?.organization_id || null;
    this.name = data?.name || null;
    this.campaignStatus = data?.campaignStatus || null;
    this.lieu_id = data?.lieu_id || null;
    this.admin = data?.admin || null;
    this.relations = data?.relations || RELATIONS_SEARCH;
    this.userTypes = data?.userTypes || [];
    this.toCampaignUpdate = data?.toCampaignUpdate ?? null;
    this.priority = data?.priority ?? null;
  }
}
