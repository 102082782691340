<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<ng-container *ngFor="let stop of stopsByDistance; let i = index">
  <div *ngIf="i < 7 && transports[stop.gare].length !== 0" class="py-1">
    <div class="d-block transport_direction mb-1">
      {{ stop.gare }}
      à {{ stop.distance | kmToMeters }}
    </div>

    <div
      *ngFor="let transport of transports[stop.gare]"
      class="transport me-2 mb-2"
    >
      <div
        [ngbTooltip]="transport.commercial_mode_plain + ' ' + transport.label"
        *ngIf="
          transport.class !== 'default_transport_design' &&
          transport.commercial_mode !== 'Tramway'
        "
        [ngClass]="transport.class"
        class="transport"
      ></div>
      <div
        [ngbTooltip]="transport.commercial_mode_plain + ' ' + transport.label"
        *ngIf="
          transport.class === 'default_transport_design' ||
          transport.commercial_mode === 'Tramway'
        "
        [ngClass]="transport.class"
        [style]="transport.style"
      >
        <span *ngIf="transport.commercial_mode !== 'bus'">
          {{ transport.commercial_mode | titlecase }}</span
        >
        {{ transport.label }}
      </div>
    </div>
  </div>
</ng-container>

<a
  *ngIf="place"
  [href]="itineraryLink"
  target="_blank"
  class="d-print-none btn btn-outline-secondary"
  rel="noopener"
  (click)="
    captureEvent({
      name: 'click-see-itinerary',
    })
  "
>
  <fa-icon [icon]="['fas', 'map']"></fa-icon>
  {{ "ITINERAIRE" | translate }}
</a>
