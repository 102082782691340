/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import { Injectable } from "@angular/core";
import {
  CountryCodes,
  DEPARTMENT_CODES,
  getDepartmentCodeFromPostalCode,
} from "@soliguide/common";
import { format } from "date-fns";

import { HOLIDAYS } from "../../../shared";
import { THEME_CONFIGURATION } from "../../../models";

@Injectable({
  providedIn: "root",
})
export class HolidaysService {
  private todayHoliday = false;
  private stringToDisplay = "";
  private listDepHolidays: string[];
  private holidayName = "";

  constructor() {
    this.listDepHolidays = [];
    this.updateService(new Date());
  }

  public updateService = (date: Date) => {
    const day: string = format(date, "yyyy-MM-dd") as string;

    this.todayHoliday = Object.keys(HOLIDAYS).includes(day);

    if (this.todayHoliday) {
      this.stringToDisplay = HOLIDAYS[day].zoneToDisplay;
      this.listDepHolidays = HOLIDAYS[day].zonesDep.includes("ALL")
        ? DEPARTMENT_CODES[CountryCodes.FR]
        : HOLIDAYS[day].zonesDep;
      this.holidayName = HOLIDAYS[day].holidayName;
    } else {
      this.stringToDisplay = "";
      this.listDepHolidays = [];
      this.holidayName = "";
    }
  };

  public isTodayHolidayForCodePostal = (postalCode: string): boolean => {
    return (
      THEME_CONFIGURATION.country === CountryCodes.FR &&
      !!postalCode &&
      this.listDepHolidays.includes(
        getDepartmentCodeFromPostalCode(CountryCodes.FR, postalCode)
      )
    );
  };

  public get isTodayHoliday(): boolean {
    return this.todayHoliday;
  }

  public get getListDepHolidays(): string[] {
    return this.listDepHolidays;
  }

  public get getStringToDisplay(): string {
    return this.stringToDisplay;
  }

  public get getHolidayName(): string {
    return this.holidayName;
  }
}
