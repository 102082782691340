/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import { Environment } from "./Environment.type";

export const environment: Environment = {
  apiUrl: "https://api.soliguide.fr/",
  locationApiUrl: "https://soliguide-location-api.cleverapps.io/",
  enableTracing: false,
  environment: "PROD",
  navitiaKey: "5fcf2bda-f1ed-40a6-8958-3ec49a0e4b6d" ?? undefined,
  posthogUrl: "https://ph.soliguide.fr",
  posthogApiKey: "phc_hWi7ZPUX3uuNLxDKgRG0IM0CMWLLKuhnuLGw7aZFKdo" ?? undefined,
  sentryDsn: "https://ca418cc5139745e0b94027b998a10633@o210011.ingest.sentry.io/5612969" ?? undefined,
  sentryProject: "soliguide-frontend" ?? undefined,
  chatWebsiteId: "aeca48ae-8b13-4714-8aa4-10c0da179332" ?? undefined,
  territoriesPresent: "33",
  territorialAnalysis: "https://superset.solinum.org/superset/dashboard/territorial-analysis/?standalone=2" ?? undefined,
  seasonalAnalysis: "https://superset.solinum.org/superset/dashboard/services-closures/?standalone=2" ?? undefined,
  searchTracking: "https://superset.solinum.org/superset/dashboard/tableau-des-recherches/?standalone=2" ?? undefined,
  olympicGames: "https://superset.solinum.org/superset/dashboard/solidata_jo_2024/?standalone=2" ?? undefined,
  praticalFilesLink: "https://support.soliguide.fr/hc" ?? undefined,
  becomeTranslatorFormLink:
    "https://airtable.com/shrZHYio1ZdnPl1Et" ?? undefined,
  proAccountCreationFormLink:
    "https://airtable.com/shrVIdI1OcUSpYXAP?prefill_Comment+vous+nous+avez+connu+?=Soliguide.fr" ?? undefined,
  donateLink: "https://www.helloasso.com/associations/solinum/formulaires/1" ?? undefined,
} as const;
