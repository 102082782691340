<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="page-content-header"></div>
<div class="container">
  <div class="page-content page-content-overlay">
    <h1>Data processing agreement</h1>

    <h2>1. Introduction</h2>
    <p>
      The purpose of the Data Protection Annex (hereinafter "<b>DPA</b>" or
      "<b>Agreement</b>") is to govern the use of the personal data of the
      Client, acting as data controller (hereinafter the "<b>Client</b>"), by
      Solinum, acting as Processor (hereinafter the "<b>Processor</b>") under
      the Agreement (hereinafter the "<b>Agreement</b>").
    </p>
    <p>
      The DPA is an integral part of the Contract signed between the Client and
      the Processor. In the event of any contradiction between the Contract and
      the DPA, the obligations set out in the DPA shall prevail with regard to
      the applicable data protection rules.
    </p>
    <p>
      All data protection terms used in the DPA (e.g. controller, processor,
      etc.) are defined in Article 4 of the General Data Protection Regulation
      ("<b>GDPR</b>").
    </p>

    <h2>2. Declaration</h2>
    <p>
      The Processor declares that it complies with all applicable data
      protection rules included in Regulation (EU) 2016/679 of the European
      Parliament and of the Council of 27 April 2016 on the protection of
      individuals with regard to the processing of personal data and on the free
      movement of such data ("<b>GDPR</b>") and Law No. 78-17 of 6 January 1978
      on data processing, files and freedoms, together referred to as the "<b
        >applicable data protection rules</b
      >".
    </p>
    <p>
      The Processor declares that it provides all the
      <b>necessary guarantees</b> to meet the requirements of the applicable
      data protection regulations and, in particular, to
      <b>guarantee the confidentiality</b> and
      <b>protection of the Client's data</b>.
    </p>
    <p>
      The Processor declares that all of its employees who are required to
      process the Client's personal data are bound by a
      <b>confidentiality clause</b> or <b>any other legal document</b> (e.g.
      rules of good conduct, information systems charter, etc.) guaranteeing the
      confidentiality of the Client's personal data.
    </p>
    <p>
      The Processor declares that it regularly <b>trains</b> its employees
      <b>and makes them aware of</b> the applicable data protection rules.
    </p>

    <h2>3. Instructions</h2>
    <p>
      The Processor undertakes to use the Client's personal data only on the
      Client's <b>documented instructions</b>.
    </p>
    <p>
      The Client undertakes to inform the Processor of any <b>changes to</b> the
      instructions that may be issued concerning the use of its personal data.
    </p>
    <p>
      The Processor shall notify the Client <b>as soon as possible</b> if the
      latter's documented instructions constitute a breach of the applicable
      data protection rules.
    </p>

    <h2>4. Compliance by default and by design</h2>
    <p>
      The Processor provides its service as is, in compliance with i)
      <b>service conformity by design</b> and ii)
      <b>service conformity by default</b>.
    </p>
    <p>
      The Processor provides a service with
      <b>all functionalities enabling the Client to meet its obligations</b> as
      data controller.
    </p>
    <p>
      Consequently, the Processor is
      <b
        >never liable for any use of the service by the Client that does not
        comply with applicable data protection regulations</b
      >.
    </p>

    <h2>5. Security</h2>
    <p>
      The Processor undertakes to guarantee the <b>security</b> of the Client's
      personal data and to implement all <b>technical</b> and
      <b>organizational measures</b>
      necessary to prevent any risk of data breach.
    </p>

    <h2>6. Data breach</h2>
    <p>
      The Processor undertakes to <b>notify</b> the Client
      <b>as soon as possible</b> and within 48 working hours of becoming aware
      of any data <b>breach</b> that may affect the Client's personal data.
    </p>
    <p>
      The Processor undertakes to provide the Client, in accordance with the
      provisions of Article 28 of the GDPR, with all the information necessary
      for the Client to process the data breach.
    </p>
    <p>
      In the event of a data breach, the Processor undertakes to take all
      necessary measures to <b>remedy</b> and <b>reduce</b> the impact of the
      breach on the Client's personal data.
    </p>
    <p>
      Unless the Client has given its express prior written consent, the
      Processor <b>is not authorized to</b> handle notifications of data
      breaches to the French supervisory authority, the CNIL. Similarly, the
      Processor is not, as a matter of principle, authorized to inform, on
      behalf of the Client, the persons concerned by the processing carried out
      under the Contract.
    </p>

    <h2>7. Help and assistance with safety</h2>
    <p>
      The Processor shall provide the Client, upon written request, with all
      <b>necessary</b> and <b>required information</b> on the
      <b>technical and organizational security measures</b> to be implemented to
      guarantee the security of its personal data.
    </p>
    <p>
      The Processor shall provide the Client, upon written request, with all
      <b>information necessary and required</b> to ensure the performance of an
      <b>impact analysis ("PIA")</b> directly related to the service provided.
    </p>
    <p>
      On the other hand, the Processor is <b>not obliged</b> to ensure or audit
      the Client's security, or to carry out PIAs for and on behalf of the
      Client. Any additional request for information may be <b>refused</b> and,
      where appropriate, an additional service may be <b>charged for</b>.
    </p>

    <h2>8. Help and assistance with data subject rights</h2>
    <p>
      The Processor shall provide the Client, upon written request, with all
      <b>necessary</b> and <b>required information</b> to enable the Client to
      fulfil its obligation to comply with the <b>requests</b> of the persons
      concerned.
    </p>
    <p>
      At the Client's written request, the Processor shall carry out the
      <b>technical actions</b> required for the Client to fulfil its obligation
      to comply with the <b>requests</b> of the persons concerned.
    </p>
    <p>
      On the other hand, the Processor is <b>not obliged</b> to manage requests
      for personal rights <b>in place</b> of <b>and on behalf</b> of the Client.
      Any additional request for such management may be refused, and may be
      subject to an additional service <b>charge</b>.
    </p>

    <h2>9. Subsequent Processors</h2>
    <p>
      In general, the Client accepts that the Processor may
      <b>recruit Sub-processors</b> as part of the performance of the Contract,
      provided that it informs the Client of any <b>changes</b> concerning such
      Sub-processors occurring during the performance of the Contract.
    </p>
    <p>
      The Client may raise <b>objections</b> by registered letter with
      acknowledgement of receipt i) if the Sub-processor is one of its
      <b>competitors</b>, ii) if the Client and the Sub-processor are in a
      <b>pre-litigation</b> or <b>litigation</b> situation, and iii) if the
      Sub-processor has been <b>convicted</b> by a data protection supervisory
      authority within one year of its recruitment by the Processor. Each of
      these situations must be <b>demonstrated</b>.
    </p>
    <p>
      In the event that the objection is admissible, the Processor has a period
      of <b>6 months</b> from receipt of the objection in which to modify the
      Sub-processor or to ensure that the Sub-processor complies with the
      applicable data protection rules.
    </p>
    <p>
      In all cases, the Processor undertakes to recruit only Sub-processors who
      present the <b>necessary and sufficient guarantees</b> to ensure the
      security and confidentiality of the Client's personal data.
    </p>
    <p>
      As such, the Processor undertakes i) to regularly check its Sub-processors
      and ii) to ensure that the contract entered into with the Sub-processor
      used as part of the service contains <b>obligations similar to</b> those
      set out in the DPA.
    </p>
    <p>
      In any event, the Processor remains <b>liable</b> for the acts of the
      Sub-processor under the Contract.
    </p>

    <h2>10. Fate of personal data</h2>
    <p>
      The Client shall inform the Processor, in writing and before the end of
      the business relationship, of its <b>choice</b> (option 1) to
      <b>return</b> the personal data to the Processor and then <b>delete</b> it
      and all existing copies, or (option 2) to <b>directly delete</b> the
      personal data and all existing copies, or (option 3) to
      <b>transfer</b> the personal data to a new service provider and then
      delete it and all existing copies. Unless otherwise stipulated in the
      Contract, option 3 must be <b>quoted for</b> by the Processor.
    </p>
    <p>
      If the Client does not inform the Processor of his choice, the Processor
      will <b>directly delete</b> the Client's data and all copies (option 2) at
      the end of the business relationship.
    </p>
    <p>
      Data deletion is irreversible. The Client is therefore advised to recover
      his data before the service is discontinued. In the event of deletion of
      the Client's data by the Processor, the Client remains solely responsible
      for the disappearance of the data and for any consequences that may arise.
    </p>
    <p>
      The Processor shall <b>certify</b> to the Client,
      <b>upon written request</b>, that the personal data and all existing
      copies thereof have been effectively deleted.
    </p>

    <h2>11. Audits</h2>
    <p>
      The Client has the right to carry out an <b>audit</b> in the form of a
      <b>written questionnaire</b> once a year to verify compliance with this
      Agreement. The questionnaire has the force of a <b>sworn undertaking</b>
      binding on the Processor.
    </p>
    <p>
      The questionnaire may be sent in any form to the Processor, who undertakes
      to reply within a maximum of <b>two months</b> of receipt.
    </p>
    <p>
      The Client also has the right to carry out an <b>on-site audit</b>, at its
      own expense, once a year, only in the event of a data <b>breach</b> or
      <b>failure</b>
      to comply with the applicable data protection rules and this DPA, as
      established by the written questionnaire.
    </p>
    <p>
      An on-site audit may be carried out either by the Client or by an
      independent third party appointed by the Client and must be notified to
      the Processor in writing at least <b>thirty (30) days</b> before the audit
      is due to take place.
    </p>
    <p>
      The Processor has the right to refuse the choice of the independent third
      party if the latter is i) a <b>competitor</b> or ii) in
      <b>pre-litigation</b> or <b>litigation</b> with the Processor. In this
      case, the Client undertakes to select a new independent third party to
      carry out the audit.
    </p>
    <p>
      The Processor may <b>refuse</b> access to certain areas for reasons of
      confidentiality or security. In this case, the Processor carries out the
      audit in these areas at its <b>own expense</b> and communicates the
      results to the Client.
    </p>
    <p>
      In the event of any deviation identified during the audit, the Processor
      undertakes to implement, <b>without delay</b>, the necessary measures to
      <b>comply</b>
      with this Agreement.
    </p>

    <h2>12. Data transfers outside the European Union</h2>
    <p>
      The Processor undertakes to take all necessary steps <b>not</b> to
      <b>transfer</b> the Client's personal data
      <b>outside the European Union</b> or to <b>recruit</b> any Sub-processor
      located <b>outside the European Union</b>.
    </p>
    <p>
      Nevertheless, should such transfers prove necessary in the context of the
      Contract, the Processor undertakes to implement all the <b>mechanisms</b>
      required to govern such transfers, such as, in particular, entering into
      <b>the standard data protection clauses</b> ("SCC") adopted by the
      European Commission.
    </p>

    <h2>13. Cooperation with the supervisory authority</h2>
    <p>
      Where this concerns processing carried out under the Contract, the
      Processor undertakes to provide, on request, all the information necessary
      for the Client to <b>cooperate</b> with the
      <b>competent supervisory authority</b>.
    </p>

    <h2>14. Contact</h2>
    <p>
      The Client and the Processor each designate a <b>contact person</b> who is
      in charge of this DPA and who is the addressee of the various
      notifications and communications that are to take place within the
      framework of the DPA.
    </p>
    <p>
      The Processor informs the Client that it has appointed Dipeeo as its
      <b>Data Protection Officer</b>, who can be contacted at the following
      address:
    </p>
    <ul>
      <li>
        Email address:
        <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a>
      </li>
      <li>
        Postal address: Dipeeo SAS, 104 avenue de la Résistance, 93100
        Montreuil, France
      </li>
      <li>
        Telephone number: <a href="tel:+33986232129">+33(0)9 86 23 21 29</a>
      </li>
    </ul>

    <h2>15. Review</h2>
    <p>
      The Processor reserves the right to modify this Agreement in the event of
      <b>changes</b> in the applicable data protection rules which would have
      the effect of modifying any of its provisions.
    </p>

    <h2>16. Applicable law</h2>
    <p>
      Notwithstanding any provision to the contrary in the Contract, this
      Agreement is governed by <b>French law</b>. Any dispute arising out of or
      in connection with the performance of this Agreement shall be subject to
      the exclusive jurisdiction of the courts within the jurisdiction of the
      <b>Court of Appeal of the place where the Processor is domiciled</b>.
    </p>
    <p>
      Nevertheless, should such transfers prove necessary in the context of the
      Contract, the Processor undertakes to implement all the mechanisms
      required to govern such transfers, such as, in particular, entering into
      the standard data protection clauses ("SDPC") adopted by the European
      Commission.
    </p>

    <h2>Appendix 1 - Description of treatments</h2>
    <table class="table">
      <thead class="bg-primary text-white">
        <tr>
          <th scope="col">Treatments</th>
          <th scope="col">Foundations</th>
          <th scope="col">Persons concerned</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Cookies banner
          </td>
          <td>Legal obligation</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Chat / chatbot (support)
          </td>
          <td>Legitimate interest</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Statistical cookies
          </td>
          <td>Prior consent</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Technical session cookies
          </td>
          <td>Legitimate interest</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Account creation
          </td>
          <td>Contract performance</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Contact form
          </td>
          <td>Pre-contractual negotiation and legitimate interest</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Geolocation</td>
          <td>Prior consent</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Database management
          </td>
          <td>Contract performance</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Account management
          </td>
          <td>Contract performance</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Hosting</td>
          <td>Cf. Register hosting foundation sheets</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Mobility information
          </td>
          <td>Legitimate interest</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Registration and login
          </td>
          <td>Contract performance</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Inviting users
          </td>
          <td>Contract performance</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Technical or transactional email messages
          </td>
          <td>Legitimate interest</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Sharing facility files
          </td>
          <td>Legitimate interest</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Account personalization
          </td>
          <td>Contract performance</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Safety and maintenance
          </td>
          <td>Legal obligation and legitimate interest</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Backup servers
          </td>
          <td>Cf. Register hosting foundation sheets</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Uploading and importing documents
          </td>
          <td>Contract performance</td>
          <td>Users and administrators</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Content translation
          </td>
          <td>Legitimate interest</td>
          <td>Users and administrators</td>
        </tr>
      </tbody>
    </table>

    <h2>Appendix 2 - Description of data processed and retention periods</h2>
    <table class="table">
      <thead class="bg-primary text-white">
        <tr>
          <th scope="col">Data categories</th>
          <th scope="col">Goals</th>
          <th scope="col">Shelf life</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row">
            Directors' identification data (e.g. surname, first name)
          </td>
          <td>Using the service</td>
          <td>
            Archive directors' data for 5 years after the end of the business
            relationship
          </td>
        </tr>
        <tr>
          <td scope="row">
            User identification data (e.g. surname, first name)
          </td>
          <td>Using the service</td>
          <td>Anonymize or delete user data upon account deletion</td>
        </tr>
        <tr>
          <td scope="row">
            Directors' contact details (e.g. email address, telephone number)
          </td>
          <td>Using the service</td>
          <td>
            Archive directors' data for 5 years after the end of the business
            relationship
          </td>
        </tr>
        <tr>
          <td scope="row">
            User contact details (e.g. email address, telephone number)
          </td>
          <td>Using the service</td>
          <td>Anonymize or delete user data upon account deletion</td>
        </tr>
        <tr>
          <td scope="row">Directors' contact details (e.g. email address)</td>
          <td>Technical and transactional notifications</td>
          <td>
            Archive directors' data for 5 years after the end of the business
            relationship
          </td>
        </tr>
        <tr>
          <td scope="row">User contact details (e.g. email address)</td>
          <td>Technical and transactional notifications</td>
          <td>Anonymize or delete user data upon account deletion</td>
        </tr>
        <tr>
          <td scope="row">
            Data relating to the professional life of directors (e.g. company,
            department, position)
          </td>
          <td>Using the service</td>
          <td>
            Archive directors' data for 5 years after the end of the business
            relationship
          </td>
        </tr>
        <tr>
          <td scope="row">
            Data relating to users' professional lives (e.g. company,
            department, position)
          </td>
          <td>Using the service</td>
          <td>Anonymize or delete user data upon account deletion</td>
        </tr>
        <tr>
          <td scope="row">
            Connection data (e.g. identifiers, IP address, logs)
          </td>
          <td>Using the service</td>
          <td>
            Delete or anonymize connection logs and IP addresses every 12 months
            at most
          </td>
        </tr>
        <tr>
          <td scope="row">Technical session cookies</td>
          <td>Using the service</td>
          <td>Delete or anonymize cookies every 13 months maximum</td>
        </tr>
        <tr>
          <td scope="row">Statistical cookies</td>
          <td>Service usage analysis</td>
          <td>Delete or anonymize cookies every 13 months maximum</td>
        </tr>
      </tbody>
    </table>

    <h2>Appendix 3 - Technical and organizational safety measures</h2>
    <table class="table">
      <thead class="bg-primary text-white">
        <tr>
          <th scope="col">Measurement categories</th>
          <th scope="col">Integrated safety measures</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row">Technical safety measures</td>
          <td>Encryption of Client/user passwords</td>
        </tr>
        <tr>
          <td scope="row">Technical safety measures</td>
          <td>Online app / online platform in HTTPS</td>
        </tr>
        <tr>
          <td scope="row">Technical safety measures</td>
          <td>Automatic logout after a certain period of inactivity</td>
        </tr>
        <tr>
          <td scope="row">Technical safety measures</td>
          <td>reCAPTCHA</td>
        </tr>
        <tr>
          <td scope="row">Organizational safety measures</td>
          <td>Team training</td>
        </tr>
        <tr>
          <td scope="row">Organizational safety measures</td>
          <td>Information systems charter</td>
        </tr>
        <tr>
          <td scope="row">Organizational safety measures</td>
          <td>Rules of conduct</td>
        </tr>
      </tbody>
    </table>

    <h2>Appendix 4 - List of Sub-processors and transfers outside the EU</h2>
    <table class="table">
      <thead class="bg-primary text-white">
        <tr>
          <th scope="col">Processors</th>
          <th scope="col">Features</th>
          <th scope="col">Server localization</th>
          <th scope="col">Transfers outside the EU</th>
          <th scope="col">Appropriate warranties</th>
          <th scope="col">Contact</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Zendesk</td>
          <td>Chat / chatbot</td>
          <td>France</td>
          <td>Transfers outside the EU (NA)</td>
          <td>Supervision of transfers (NA)</td>
          <td>
            <a href="mailto:euprivacy@zendesk.com">euprivacy&#64;zendesk.com</a>
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Zendesk</td>
          <td>Statistical cookies</td>
          <td>France</td>
          <td>Transfers outside the EU (NA)</td>
          <td>Supervision of transfers (NA)</td>
          <td>
            <a href="mailto:euprivacy@zendesk.com">euprivacy&#64;zendesk.com</a>
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Dagster Cloud
          </td>
          <td>Hosting</td>
          <td>France</td>
          <td>Transfers outside the EU (NA)</td>
          <td>Supervision of transfers (NA)</td>
          <td>
            <a href="mailto:privacy@elementl.com">privacy&#64;elementl.com</a>
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Google Analytics
          </td>
          <td>Statistical cookies</td>
          <td>United States</td>
          <td>Transfers to the United States</td>
          <td>
            <a href="https://business.safety.google/adsprocessorterms/sccs/p2p/"
              >SCC from Google Ads</a
            >
          </td>
          <td>
            <a href="mailto:data-protection-office@google.com"
              >data-protection-office&#64;google.com</a
            >
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Google Maps</td>
          <td>Mapping / geolocation</td>
          <td>United States</td>
          <td>Transfers to the United States</td>
          <td>
            <a href="https://policies.google.com/privacy/frameworks?hl=fr"
              >SCC from Google</a
            >
          </td>
          <td>
            <a href="mailto:data-protection-office@google.com"
              >data-protection-office&#64;google.com</a
            >
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Navitia</td>
          <td>Mobility information</td>
          <td>France</td>
          <td>Transfers outside the EU (NA)</td>
          <td>Supervision of transfers (NA)</td>
          <td><a href="mailto:dpo@kisio.com">dpo&#64;kisio.com</a></td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">PostHog</td>
          <td>Statistical cookies</td>
          <td>France or European Union</td>
          <td>Transfers outside the EU (NA)</td>
          <td>Supervision of transfers (NA)</td>
          <td>
            <a href="mailto:privacy@posthog.com">privacy&#64;posthog.com</a>
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Sentry</td>
          <td>Monitoring</td>
          <td>United States</td>
          <td>Transfers to the United States</td>
          <td>SCC via DPA</td>
          <td><a href="mailto:legal@sentry.io">legal&#64;sentry.io</a></td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Weblate</td>
          <td>Translation</td>
          <td>European Union</td>
          <td>Transfers outside the EU (NA)</td>
          <td>Awaiting audit</td>
          <td>
            <a href="mailto:privacy@weblate.org">privacy&#64;weblate.org</a>
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Google Translate
          </td>
          <td>Translation</td>
          <td>France</td>
          <td>Transfers outside the EU (NA)</td>
          <td>Supervision of transfers (NA)</td>
          <td>
            <a href="mailto:data-protection-office@google.com"
              >data-protection-office&#64;google.com</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      <em
        >Certified by
        <a href="https://www.dipeeo.com/"><u>Dipeeo</u></a> &reg;</em
      >
    </p>
  </div>
</div>
