/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
export const NAVITIA_REGIONS: { [key: string]: string } = {
  ariege: "fr-sw",
  aude: "fr-se",
  "auvergne-rhone-alpes": "fr-se",
  "bourgogne-franche-comte": "fr-se",
  aveyron: "fr-sw",
  bretagne: "fr-nw",
  "centre-val-de-loire": "fr-nw",
  gard: "fr-se",
  gers: "fr-sw",
  "grand-est": "fr-ne",
  "haute-garonne": "fr-sw",
  "hautes-pyrenees": "fr-sw",
  "hauts-de-france": "fr-ne",
  herault: "fr-se",
  "ile-de-france": "fr-idf",
  lot: "fr-sw",
  lozere: "fr-se",
  normandie: "fr-nw",
  "nouvelle-aquitaine": "fr-sw",
  "pays-de-la-loire": "fr-nw",
  "provence-alpes-cote-dazur": "fr-se",
  "pyrenees-orientales": "fr-se",
  tarn: "fr-sw",
  "tarn-et-garonne": "fr-sw",
};
