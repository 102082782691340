<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div
  *ngIf="
    place &&
    needUpdate &&
    canEdit &&
    campaignIsActive &&
    place.campaigns.runningCampaign.toUpdate
  "
>
  <div class="alert alert-warning">
    <div class="my-2 fw-bold text-center">
      {{
        "SOLIGUIDE_TO_UPDATE_CAMPAIGN_NAME"
          | translate
            : {
                campaignName: CAMPAIGN_NAME,
                brandName: THEME_CONFIGURATION.brandName
              }
      }}
      <div class="container-md mx-auto">
        <button
          type="button"
          class="btn btn-large col-8 btn-primary mt-3 btn-maj"
          (click)="openNoChangeModal()"
        >
          {{ "NO_CHANGE" | translate }}
        </button>
        <a
          target="_blank"
          class="btn btn-large col-8 btn-secondary mt-3 btn-maj"
          [routerLink]="[routePrefix, 'campaign', 'fiche', place.lieu_id]"
          (click)="
            captureEvent({
              name: 'click-complete-campaign-update-button-banner'
            })
          "
        >
          {{ "TO_UPDATE" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>

<ng-template #noChangeModal let-modal>
  <app-campaign-no-change-modal
    [place]="place"
    (parentCaptureEvent)="captureEvent($event)"
  ></app-campaign-no-change-modal>
</ng-template>
