<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<label for="searchInput">
  {{ "WHAT_ARE_YOU_LOOKING_FOR" | translate }}
</label>
<input
  type="text"
  id="searchInput"
  [placeholder]="'PLACEHOLDER_SEARCH' | translate"
  autocomplete="off"
  #searchValueInput
  [(ngModel)]="search.label"
  [value]="search.label"
  name="catInput"
  (focus)="showSuggestions = true"
  appCleanStr="alphanumeric"
  (input)="updateSearchWordOnly(searchValueInput.value)"
  (keyup.enter)="searchWord(searchValueInput.value, 'enter-pressed')"
/>

<button
  class="search-input-button"
  type="button"
  [attr.aria-label]="'DELETE_ENTRY' | translate"
  *ngIf="search.label !== null"
  (click)="clearCategoryInput()"
>
  <fa-icon [icon]="['fas', 'times']" aria-hidden="true"></fa-icon>
</button>
<div id="autocomplete-results" *ngIf="showSuggestions">
  <div
    *ngIf="
      !searching &&
      autoCompleteResults.categories.length === 0 &&
      autoCompleteResults.terms.length === 0 &&
      !searchValueInput.value
    "
  >
    {{ "SAISIR_LETTRES" | translate }}
  </div>
  <div *ngIf="searching">
    <img
      id="typeLoading"
      src="assets/images/loaders/typeLoading.svg"
      [alt]="'LOADING' | translate"
    />
  </div>

  <div
    class="mb-2"
    *ngIf="!searching && autoCompleteResults.categories.length !== 0"
  >
    <b>{{ "CATEGORIES" | translate }}</b>
    <ul>
      <ng-container
        *ngFor="let category of autoCompleteResults.categories; let i = index"
      >
        <li
          tabindex="0"
          *ngIf="i < 6"
          (click)="searchCategory(category.categoryId, 'mouse-clicked')"
          (keyup.enter)="searchCategory(category.categoryId, 'enter-pressed')"
        >
          {{ category.categoryId | uppercase | translate }}
        </li>
      </ng-container>
    </ul>
  </div>
  <div *ngIf="!searching && searchValueInput.value" id="search-word">
    <b>{{ "SEARCH_EXPRESSION" | translate }} :</b>
    <ul>
      <li
        tabindex="0"
        (click)="searchWord(searchValueInput.value, 'mouse-clicked')"
        (keyup.enter)="searchWord(searchValueInput.value, 'enter-pressed')"
      >
        {{ searchValueInput.value }}
      </li>
    </ul>
  </div>
</div>
